import { useState, useContext } from "react";
import { useMessage } from "@messageformat/react";
import { Heading, Button, Paragraph, TextField, Notification } from "@purpurds/purpur";
import styles from "./Checkout.module.scss";
import { B2bAddressSearch } from "@telia/b2b-address-search/react-cjs";
import { AddressInformation, B2bAddressSearchCustomEvent } from "@telia/b2b-address-search";
import { UserContext } from "../context/UserContext";

type Props = {
  setStep: () => void;
  setEditStep: () => void;
  isActive: boolean;
  scopeId: string;
};

export const Invoice = ({ setStep, setEditStep, isActive, scopeId }: Props) => {
  const checkout = useMessage("checkout");
  const error = useMessage("checkoutError");
  const {
    invoiceAddress,
    invoiceReference,
    additionalInvoiceInfo,
    setInvoiceAddress,
    setInvoiceReference,
    setAdditionalInvoiceInfo,
    saveChangesNotification,
  } = useContext(UserContext);

  const [valid, setValid] = useState({
    reference: true,
    address: true,
  });

  const handleAddressChange = (e: B2bAddressSearchCustomEvent<AddressInformation | null>) => {
    setInvoiceAddress(e.detail ?? undefined);
  };

  const handleSetStep = async () => {
    const isValid = await validate();
    if (!isValid) return;
    setStep();
  };

  const validate = async () => {
    let updatedValid = {
      reference: true,
      address: true,
    };
    if (!invoiceReference) {
      updatedValid.reference = false;
    }
    if (invoiceAddress?.streetName === "") {
      updatedValid.address = false;
    }
    setValid(updatedValid);
    return Object.values(updatedValid).every((value) => value === true);
  };

  const errorMessage = (field: string) => {
    if (!valid.reference && field === "reference") {
      return error.default();
    }
    if (!valid.address && field === "address") {
      return error.address();
    }

    return undefined;
  };

  const handleSetInvoiceReference = (value: string) => {
    setValid({ ...valid, reference: true });
    setInvoiceReference(value);
  };

  return (
    <div className={styles.card}>
      <span>
        <Heading variant="title-200" tag="h2">
          {checkout.billing()}
        </Heading>
        {isActive && <Paragraph>{checkout.invoiceTypeText()}</Paragraph>}
      </span>
      {isActive && (
        <div className={styles.cardContent}>
          <TextField
            id="invoice-reference"
            required
            value={invoiceReference}
            label={checkout.invoiceReference()}
            errorText={errorMessage("reference")}
            onChange={(e) => handleSetInvoiceReference(e.target.value)}
          />
          <B2bAddressSearch
            label={checkout.address()}
            scopeId={scopeId}
            debounceTimeoutMs={300}
            manualAddressEnabled
            defaultAddress={JSON.stringify(invoiceAddress)}
            error={errorMessage("address")}
            onAddressSelected={handleAddressChange}
            required
          />
          <TextField
            id="additional-invoice-info"
            label={checkout.invoiceAdditionalInfo()}
            value={additionalInvoiceInfo}
            helperText={checkout.additionalInfoHelperText()}
            onChange={(e) => setAdditionalInvoiceInfo(e.target.value)}
          />
          <Button variant="secondary" onClick={handleSetStep}>
            {checkout.continue()}
          </Button>
          {saveChangesNotification && (
            <Notification heading={error.almostDoneHeading()} headingTag="h3" status="error">
              {error.almostDoneBody()}
            </Notification>
          )}
        </div>
      )}
      {!isActive && invoiceAddress && invoiceReference && (
        <>
          <AddressSummary
            invoiceAddress={invoiceAddress}
            invoiceReference={invoiceReference}
            additionalInvoiceInfo={additionalInvoiceInfo}
          />
          <Button variant="secondary" onClick={setEditStep}>
            {checkout.change()}
          </Button>
        </>
      )}
    </div>
  );
};

type InvoiceSummaryProps = {
  invoiceAddress: AddressInformation;
  invoiceReference: string;
  additionalInvoiceInfo: string;
};
const AddressSummary = ({
  invoiceAddress,
  invoiceReference,
  additionalInvoiceInfo,
}: InvoiceSummaryProps) => {
  return (
    <div>
      <Paragraph>{invoiceReference}</Paragraph>
      <Paragraph>
        {invoiceAddress.streetName} {invoiceAddress.streetNumber} {invoiceAddress.entrance}
      </Paragraph>
      <Paragraph>
        {invoiceAddress.postalCode} {invoiceAddress.city}
      </Paragraph>
      <Paragraph>{additionalInvoiceInfo}</Paragraph>
    </div>
  );
};
