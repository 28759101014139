// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NgTJs6fsJncUiHPXRMYJ{display:flex;flex-direction:column;gap:var(--purpur-spacing-300)}.kf11H7LE2vljt9xmJ82Q{display:flex;flex-direction:column;gap:var(--purpur-spacing-400)}.kf11H7LE2vljt9xmJ82Q button{align-self:flex-start}.YAVE2upVOkD1bsqb4N1w{display:flex;flex-direction:column;gap:var(--purpur-spacing-300)}.rWoAd6tVpLqN_Z6zIdsV textarea{resize:none}.eMcKvhxpiyhkS_bsk_Bx{display:flex;gap:var(--purpur-spacing-250)}.eMcKvhxpiyhkS_bsk_Bx>*{flex:1}.z1L46CQFWbS5vekjRPmw{display:flex;flex-direction:column;gap:var(--purpur-spacing-200)}`, "",{"version":3,"sources":["webpack://./src/components/Checkout.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,6BACE,qBAAA,CAGJ,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CAGA,+BACE,WAAA,CAGJ,sBACE,YAAA,CACA,6BAAA,CACA,wBACE,MAAA,CAGJ,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA","sourcesContent":[".checkout {\n  display: flex;\n  flex-direction: column;\n  gap: var(--purpur-spacing-300);\n}\n.card {\n  display: flex;\n  flex-direction: column;\n  gap: var(--purpur-spacing-400);\n  button {\n    align-self: flex-start;\n  }\n}\n.cardContent {\n  display: flex;\n  flex-direction: column;\n  gap: var(--purpur-spacing-300);\n}\n.otherInformationField {\n  textarea {\n    resize: none;\n  }\n}\n.inline {\n  display: flex;\n  gap: var(--purpur-spacing-250);\n  > * {\n    flex: 1;\n  }\n}\n.deliverySummary {\n  display: flex;\n  flex-direction: column;\n  gap: var(--purpur-spacing-200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkout": `NgTJs6fsJncUiHPXRMYJ`,
	"card": `kf11H7LE2vljt9xmJ82Q`,
	"cardContent": `YAVE2upVOkD1bsqb4N1w`,
	"otherInformationField": `rWoAd6tVpLqN_Z6zIdsV`,
	"inline": `eMcKvhxpiyhkS_bsk_Bx`,
	"deliverySummary": `z1L46CQFWbS5vekjRPmw`
};
export default ___CSS_LOADER_EXPORT___;
