
export default {
  pageTitle: {
    main: () => "Property hardware",
    orderConfirmation: () => "Thank you for your order!"
  },
  summary: {
    toCheckout: () => "Continue to checkout",
    submit: () => "Place order",
    remove: () => "Remove",
    "continue": () => "Continue",
    selection: () => "Your selections",
    kr: () => "SEK"
  },
  checkout: {
    delivery: () => "Delivery address",
    companyName: () => "Company name",
    address: () => "Address",
    receiver: () => "Receiver",
    "continue": () => "Continue",
    contact: () => "Contact and reference",
    billing: () => "Invoice information",
    firstname: () => "First name",
    lastname: () => "Last name",
    email: () => "Email",
    mobileNumber: () => "Mobile phone number",
    reference: () => "Reference or note",
    referenceHelpText: () => "If, for example, you have your own order number",
    change: () => "Change",
    invoiceReference: () => "Invoice reference",
    invoiceAddress: () => "Invoice address",
    invoiceAdditionalInfo: () => "Other address information (optional)",
    additionalInfoHelperText: () => "Here you can enter, for example, c/o or ATT.",
    otherInformation: () => "Other information",
    invoiceTypeText: () => "We send the invoices by mail. Unfortunately, we cannot send e-invoices.",
    firstAndLastName: () => "First and last name",
    orderer: () => "Orderer"
  },
  orderConfirmation: {
    products: () => "products",
    orderNumber: () => "Order number:",
    details: () => "Details",
    amount: () => "Amount:",
    deliveryDetails: () => "Delivery details",
    billingAccount: () => "Billing account",
    receiverInfo: () => "Receiver contact information",
    followOrder: () => "Follow order",
    toProductsAndServices: () => "To products & services",
    confirmationText: () => "The confirmation and summary have also been sent to your email."
  },
  priceSummary: {
    total: () => "total",
    oneTimeFee: () => "One time fee",
    exclVat: () => "All prices are shown excluding VAT.",
    kr: () => "SEK",
    vatExcluded: () => "VAT excluded"
  },
  contactError: {
    firstname: () => "First name is mandatory",
    lastname: () => "Last name is mandatory",
    email: () => "Email is mandatory",
    mobile: () => "Mobile number is mandatory"
  },
  checkoutError: {
    defaultHeading: () => "Something went wrong!",
    defaultOrderMessage: () => "We could not handle your order at the moment, please try again later",
    companyName: () => "Company name is mandatory",
    address: () => "Address is mandatory",
    "default": () => "This field is mandatory",
    invalid: () => "Invalid format",
    almostDoneHeading: () => "Almost done!",
    almostDoneBody: () => "Save all changes to complete your order."
  },
  fetchError: {
    access: () => "We cannot read your user permission at the moment, please try again later.",
    organisation: () => "We cannot read your organisation information, please try again later.",
    products: () => "We cannot retrieve any products at the moment, please try again later.",
    userInfo: () => "We cannot read your user information at the moment, please try again later.",
    noPermissionHeading: () => "No permission",
    noPermissionMessage: () => "Selected organisation does not have permission to order property hardware.",
    defaultHeading: () => "Something went wrong!"
  }
}