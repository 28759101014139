// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nouDed2mioZ_pH_f2hI8{display:flex;flex-direction:column;gap:var(--purpur-spacing-200);border-top:solid 1px var(--purpur-color-gray-100);padding-top:var(--purpur-spacing-400)}.Vd1mq9VWRLPiInQbN6GE{display:flex;justify-content:space-between;padding-bottom:var(--purpur-spacing-50)}`, "",{"version":3,"sources":["webpack://./src/components/PriceSummary.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,iDAAA,CACA,qCAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,uCAAA","sourcesContent":[".priceSummary {\n  display: flex;\n  flex-direction: column;\n  gap: var(--purpur-spacing-200);\n  border-top: solid 1px var(--purpur-color-gray-100);\n  padding-top: var(--purpur-spacing-400);\n}\n.inline {\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: var(--purpur-spacing-50);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"priceSummary": `nouDed2mioZ_pH_f2hI8`,
	"inline": `Vd1mq9VWRLPiInQbN6GE`
};
export default ___CSS_LOADER_EXPORT___;
