import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./components/Root";
import { Scaffolding } from "./Scaffolding";
import { ProductProvider } from "./context/ProductContext";
import { UserProvider } from "./context/UserContext";
import "@purpurds/purpur/styles";
import "@telia/b2x-inline-dropdown";
import "@telia/b2b-autocomplete-input";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "b2x-teaser-cluster": unknown;
      "b2x-slider": unknown;
    }
  }
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  // Remove or set to createRoot when upgrading to React 18
  renderType: "render",
  rootComponent: () => (
    <Scaffolding>
      <UserProvider>
        <ProductProvider>
          <Root />
        </ProductProvider>
      </UserProvider>
    </Scaffolding>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
