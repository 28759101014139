import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { createContext, useEffect, useState } from "react";
import { AddressInformation } from "@telia/b2b-address-search";
import { getOrganizations, getLoggedInUserInfo } from "@telia/b2b-logged-in-service";
import { GetOrganizationResponse } from "@telia/b2b-rest-client/dist/corp-scope-information";
import { CheckoutStep, Receiver, Contact } from "../typings/products";
import { corpProptechService } from "@telia/b2b-rest-client";
import { corpAddressLookup } from "@telia/b2b-rest-client";
interface Props {
  children?: React.ReactNode;
}

type Access = "access" | "denied" | "loading";

type Error = "organisation" | "userInfo" | "access" | "products";

export const UserContext = createContext<{
  scopeId: string;
  tscid: string;
  hasAccess: Access;
  caseId: string | undefined;
  companyName: string;
  address: AddressInformation | undefined;
  receiver: Receiver;
  contact: Contact;
  invoiceAddress: AddressInformation | undefined;
  invoiceReference: string;
  additionalInvoiceInfo: string;
  otherInformation: string;
  checkoutStep: CheckoutStep;
  checkoutStepCopy: CheckoutStep | undefined;
  loggedInUser: Contact;
  fetchError: Error | undefined;
  saveChangesNotification: boolean;
  setAdditionalInvoiceInfo: React.Dispatch<React.SetStateAction<string>>;
  setAddress: React.Dispatch<React.SetStateAction<AddressInformation | undefined>>;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  setContact: React.Dispatch<React.SetStateAction<Contact>>;
  setInvoiceAddress: React.Dispatch<React.SetStateAction<AddressInformation | undefined>>;
  setInvoiceReference: React.Dispatch<React.SetStateAction<string>>;
  setOtherInformation: React.Dispatch<React.SetStateAction<string>>;
  setReceiver: React.Dispatch<React.SetStateAction<Receiver>>;
  setTscid: React.Dispatch<React.SetStateAction<string>>;
  setCaseId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCheckoutStep: React.Dispatch<React.SetStateAction<CheckoutStep>>;
  setCheckoutStepCopy: React.Dispatch<React.SetStateAction<CheckoutStep | undefined>>;
  setLoggedInUser: React.Dispatch<React.SetStateAction<Contact>>;
  setFetchError: React.Dispatch<React.SetStateAction<Error | undefined>>;
  setSaveChangesNotification: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  address: undefined,
  scopeId: "",
  hasAccess: "loading",
  caseId: undefined,
  tscid: "",
  companyName: "",
  receiver: {
    firstname: "",
    lastname: "",
    mobile: "",
  },
  contact: {
    firstName: "",
    lastName: "",
    mail: "",
    phoneNumber: "",
  },
  invoiceAddress: undefined,
  invoiceReference: "",
  additionalInvoiceInfo: "",
  otherInformation: "",
  checkoutStep: "delivery",
  checkoutStepCopy: undefined,
  loggedInUser: {
    firstName: "",
    lastName: "",
    mail: "",
    phoneNumber: "",
  },
  saveChangesNotification: false,
  fetchError: undefined,
  setAdditionalInvoiceInfo: () => {},
  setAddress: () => {},
  setCompanyName: () => {},
  setContact: () => {},
  setInvoiceAddress: () => {},
  setInvoiceReference: () => {},
  setOtherInformation: () => {},
  setReceiver: () => {},
  setTscid: () => {},
  setCaseId: () => {},
  setCheckoutStep: () => {},
  setCheckoutStepCopy: () => {},
  setLoggedInUser: () => {},
  setFetchError: () => {},
  setSaveChangesNotification: () => {},
});

export const UserProvider = ({ children }: Props) => {
  const [scopeId, setScopeId] = useState<string>("");
  const [tscid, setTscid] = useState<string>("");
  const [hasAccess, setHasAccess] = useState<Access>("loading");
  const [organisations, setOrganisations] = useState<GetOrganizationResponse[]>();
  const [companyName, setCompanyName] = useState<string>("");
  const [address, setAddress] = useState<AddressInformation | undefined>();
  const [receiver, setReceiver] = useState<Receiver>({ firstname: "", lastname: "", mobile: "" });
  const [contact, setContact] = useState<Contact>({
    firstName: "",
    lastName: "",
    mail: "",
    phoneNumber: "",
  });
  const [loggedInUser, setLoggedInUser] = useState<Contact>({
    firstName: "",
    lastName: "",
    mail: "",
    phoneNumber: "",
  });
  const [invoiceAddress, setInvoiceAddress] = useState<AddressInformation | undefined>();
  const [invoiceReference, setInvoiceReference] = useState<string>("");
  const [additionalInvoiceInfo, setAdditionalInvoiceInfo] = useState<string>("");
  const [otherInformation, setOtherInformation] = useState<string>("");
  const [checkoutStep, setCheckoutStep] = useState<CheckoutStep>("delivery");
  const [checkoutStepCopy, setCheckoutStepCopy] = useState<CheckoutStep | undefined>();
  const [caseId, setCaseId] = useState<string | undefined>();
  const [fetchError, setFetchError] = useState<Error | undefined>();
  const [saveChangesNotification, setSaveChangesNotification] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      await getScopeId();
      const response = await fetchLoggedInUser();
      setLoggedInUser(response);
    }

    fetchData();
  }, []);

  useEffect(() => {
    getOrganisations();
  }, [tscid]);

  useEffect(() => {
    if (tscid && scopeId) {
      checkProptechAccess();
      getLegalAddress();
    }
  }, [tscid, scopeId]);

  async function getScopeId() {
    const response = await getScopeIdOrThrow();
    setScopeId(response);
  }
  async function fetchLoggedInUser() {
    try {
      const response = await getLoggedInUserInfo();
      return response;
    } catch {
      setFetchError("userInfo");
    }
  }

  async function getOrganisations() {
    let response;
    try {
      response = await getOrganizations();
    } catch {
      setFetchError("organisation");
    }
    setOrganisations(response);
    setOrganisationName();
  }

  async function checkProptechAccess() {
    setHasAccess("loading");
    try {
      const response = await corpProptechService.HardwareControllerService.hasAccess(
        scopeId,
        tscid
      );
      const access = response.hasAccess ? "access" : "denied";
      setHasAccess(access);
    } catch {
      setFetchError("access");
      setHasAccess("denied");
    }
  }

  function setOrganisationName() {
    const name = organisations?.find((val) => val.tscid === tscid)?.name;
    if (!name) return;
    setCompanyName(name);
  }

  async function getLegalAddress() {
    const response = await corpAddressLookup.AddressLookupControllerService.getLegalAddress(
      scopeId,
      tscid,
      true
    );
    const legalAddress = response?.structuredAddressSuggestions?.[0];
    if (!legalAddress) return;
    setInvoiceAddress(legalAddress as AddressInformation);
  }

  const value = {
    additionalInvoiceInfo,
    address,
    caseId,
    companyName,
    contact,
    fetchError,
    hasAccess,
    invoiceAddress,
    invoiceReference,
    loggedInUser,
    otherInformation,
    receiver,
    scopeId,
    tscid,
    checkoutStep,
    checkoutStepCopy,
    saveChangesNotification,
    setAdditionalInvoiceInfo,
    setAddress,
    setCaseId,
    setCompanyName,
    setContact,
    setFetchError,
    setInvoiceAddress,
    setInvoiceReference,
    setOtherInformation,
    setReceiver,
    setTscid,
    setCheckoutStep,
    setCheckoutStepCopy,
    setLoggedInUser,
    setSaveChangesNotification,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
