import * as React from "react";
import {
  HardwareProductBundle,
  HardwareProduct,
} from "@telia/b2b-rest-client/dist/corp-proptech-service";
import { SelectedProduct } from "../typings/products";
import { UserContext } from "./UserContext";
import { fetchProducts } from "../service/ProptechService";
interface Props {
  children?: React.ReactNode;
}

type Step = "product-listing" | "checkout" | "order-confirmation";

export const ProductContext = React.createContext<{
  hardwareBundles: HardwareProductBundle[];
  selectedProducts: SelectedProduct[];
  step: Step;
  fetchingProducts: boolean;
  totalOneTimeFee: () => number;
  setSelectedProducts: React.Dispatch<React.SetStateAction<SelectedProduct[]>>;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  resetToDefault: () => void;
}>({
  hardwareBundles: [] as HardwareProductBundle[],
  selectedProducts: [] as SelectedProduct[],
  step: "product-listing",
  fetchingProducts: true,
  setSelectedProducts: () => {},
  setStep: () => {},
  totalOneTimeFee: () => 0,
  resetToDefault: () => {},
});

export const ProductProvider = ({ children }: Props) => {
  const [hardwareBundles, setHardwareBundles] = React.useState<HardwareProductBundle[]>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<SelectedProduct[]>([]);
  const [step, setStep] = React.useState<Step>("product-listing");
  const [fetchingProducts, setFetchingProducts] = React.useState<boolean>(false);
  const { scopeId, fetchError, setFetchError } = React.useContext(UserContext);

  React.useEffect(() => {
    if (scopeId) {
      getProducts(scopeId);
    }
  }, [scopeId]);

  React.useEffect(() => {
    if (selectedProducts.length === 0) {
      setStep("product-listing");
    }
  }, [selectedProducts]);

  const totalOneTimeFee = () => {
    let price = 0;
    selectedProducts.forEach((product) => {
      price += Number(product.price) * product.quantity;
    });
    return price;
  };

  const resetToDefault = () => {
    setSelectedProducts([] as SelectedProduct[]);
    setStep("product-listing");
  };

  const getProducts = async (scopeId: string) => {
    setFetchingProducts(true);
    try {
      const products = await fetchProducts(scopeId);
      setHardwareBundles(products);
    } catch {
      if (fetchError) return;
      setFetchError("products");
    } finally {
      setFetchingProducts(false);
    }
  };
  const value = {
    hardwareBundles,
    resetToDefault,
    selectedProducts,
    fetchingProducts,
    setSelectedProducts,
    setStep,
    step,
    totalOneTimeFee,
  };
  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};
