// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rqv7eizTjI22Bmzko3p6{background-color:var(--purpur-color-gray-50)}.oeY8fgXzmre97jXQNI9S{display:flex;justify-content:center}.DIYsUox_Gw7kQUkN6o7O{position:relative}.nAR_FkNuvR4k5NsWV4Ig{padding-top:var(--purpur-spacing-200)}.pwiwse8uKVGff4h06fp3{display:none}._CF9UmLIlxtnJHDJ5I_D{display:flex;flex-direction:column;gap:var(--purpur-spacing-300)}.zVAW4OhP_FqDKfQakyVV{display:flex;gap:var(--purpur-spacing-300)}.dI0sgLx0IEoF6QQ7lbXO{height:50rem}.nMIXR94cSoqQFCvueKXI{height:30rem}.vQFXMBJO8O4ilkFAtj7m{padding-top:var(--purpur-spacing-600)}.D00O8PnlUy7LqbcQxUvR{padding-top:var(--purpur-spacing-600)}`, "",{"version":3,"sources":["webpack://./src/components/Root.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CAEF,sBACE,YAAA,CACA,sBAAA,CAEF,sBACE,iBAAA,CAEF,sBACE,qCAAA,CAEF,sBACE,YAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,YAAA,CAEF,sBACE,YAAA,CAEF,sBACE,qCAAA,CAEF,sBACE,qCAAA","sourcesContent":[".proptechHardware {\n  background-color: var(--purpur-color-gray-50);\n}\n.content {\n  display: flex;\n  justify-content: center;\n}\n.summaryCol {\n  position: relative;\n}\n.contentWrapper {\n  padding-top: var(--purpur-spacing-200);\n}\n.hideTrigger {\n  display: none;\n}\n\n.productSkeletonWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: var(--purpur-spacing-300);\n}\n.inlineSkeleton {\n  display: flex;\n  gap: var(--purpur-spacing-300);\n}\n\n.skeletonCard {\n  height: 50rem;\n}\n.skeletonSummary {\n  height: 30rem;\n}\n.skeletonWrapper {\n  padding-top: var(--purpur-spacing-600);\n}\n.notificationWrapper {\n  padding-top: var(--purpur-spacing-600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"proptechHardware": `rqv7eizTjI22Bmzko3p6`,
	"content": `oeY8fgXzmre97jXQNI9S`,
	"summaryCol": `DIYsUox_Gw7kQUkN6o7O`,
	"contentWrapper": `nAR_FkNuvR4k5NsWV4Ig`,
	"hideTrigger": `pwiwse8uKVGff4h06fp3`,
	"productSkeletonWrapper": `_CF9UmLIlxtnJHDJ5I_D`,
	"inlineSkeleton": `zVAW4OhP_FqDKfQakyVV`,
	"skeletonCard": `dI0sgLx0IEoF6QQ7lbXO`,
	"skeletonSummary": `nMIXR94cSoqQFCvueKXI`,
	"skeletonWrapper": `vQFXMBJO8O4ilkFAtj7m`,
	"notificationWrapper": `D00O8PnlUy7LqbcQxUvR`
};
export default ___CSS_LOADER_EXPORT___;
