import { Heading, Paragraph } from "@purpurds/purpur";
import { ProductContext } from "../context/ProductContext";
import { useContext } from "react";
import styles from "./PriceSummary.module.scss";
import { useMessage } from "@messageformat/react";
import { currentLanguage } from "@telia/b2b-i18n";

export const PriceSummary = () => {
  const t = useMessage("priceSummary");
  const { selectedProducts, totalOneTimeFee } = useContext(ProductContext);

  const productPriceText = (price: number) => {
    return new Intl.NumberFormat(currentLanguage(), {
      style: "currency",
      currency: "SEK",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(price);
  };
  return (
    <div className={styles.priceSummary}>
      <Heading tag="h2" variant="title-100">
        {t.total()}
      </Heading>
      <span>
        <span className={styles.inline}>
          <Paragraph>{t.oneTimeFee()}</Paragraph>
          <Paragraph>{productPriceText(totalOneTimeFee())}</Paragraph>
        </span>
        <Paragraph variant="additional-100">{t.exclVat()}</Paragraph>
      </span>
    </div>
  );
};
