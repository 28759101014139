import { useMessage } from "@messageformat/react";
import { Heading, Paragraph, TextField, Button } from "@purpurds/purpur";
import { useContext, useEffect, ChangeEvent, useState } from "react";
import { UserContext } from "../context/UserContext";
import styles from "./Checkout.module.scss";
import { Contact } from "../typings/products";
import { corpValidationService } from "@telia/b2b-rest-client";

type Props = {
  setStep: () => void;
  setEditStep: () => void;
  isActive: boolean;
  scopeId: string;
};

type InvalidItem = "valid" | "invalid" | "missing";

type Valid = {
  email: InvalidItem;
  mobile: InvalidItem;
};

export const Orderer = ({ scopeId, setStep, setEditStep, isActive }: Props) => {
  const { contact, loggedInUser, setContact } = useContext(UserContext);
  const t = useMessage("checkout");
  const error = useMessage("checkoutError");
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState<Valid>({
    email: "valid",
    mobile: "valid",
  });

  useEffect(() => {
    const contactInfo: Contact = {
      firstName: loggedInUser.firstName,
      lastName: loggedInUser.lastName,
      phoneNumber: loggedInUser.phoneNumber || contact.phoneNumber,
      mail: loggedInUser.mail || contact.mail,
    };
    setContact(contactInfo);
  }, [isActive]);

  const handleSetStep = async () => {
    setLoading(true);
    const isValid = await validate();
    setLoading(false);
    if (!isValid) return;

    setStep();
  };

  const validate = async () => {
    const newValid = { ...valid };

    newValid.email = await validateField(contact.mail, "EMAIL");
    newValid.mobile = await validateField(contact.phoneNumber, "PHONENUMBER");

    setValid(newValid);
    return Object.values(newValid).every((val) => val === "valid");
  };

  const validateField = async (
    value: string,
    type: "EMAIL" | "PHONENUMBER"
  ): Promise<InvalidItem> => {
    if (!value) {
      return "missing";
    }
    if (type) {
      const response = await validateRequest(type, value);
      return response ? "valid" : "invalid";
    }
    return "valid";
  };

  const validateRequest = async (type: string, value: string) => {
    if (!value) return true;

    try {
      const response = await corpValidationService.ValidationControllerService.validate(scopeId, {
        type,
        value,
      });
      return !!response;
    } catch (e) {
      return false;
    }
  };
  const errorMessage = (field: keyof Valid) => {
    if (valid[field] === "missing") {
      return error.default();
    }
    if (valid[field] === "invalid") {
      return error.invalid();
    }

    return undefined;
  };

  const setPhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setValid((prevValid) => ({
      ...prevValid,
      mobile: "valid",
    }));
    setContact({ ...contact, phoneNumber: e.target.value });
  };
  const setEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setValid((prevValid) => ({
      ...prevValid,
      email: "valid",
    }));
    setContact({ ...contact, mail: e.target.value });
  };

  return (
    <div>
      <div className={styles.card}>
        <Heading tag="h2" variant="title-200">
          {t.orderer()}
        </Heading>
        {isActive && (
          <>
            <div className={styles.cardContent}>
              <span>
                <Paragraph variant="paragraph-100-bold">{t.firstAndLastName()}</Paragraph>
                <Paragraph>
                  {contact?.firstName} {contact?.lastName}
                </Paragraph>
              </span>

              <TextField
                required
                id="mobile"
                label={t.mobileNumber()}
                value={contact?.phoneNumber}
                errorText={errorMessage("mobile")}
                onChange={setPhoneNumber}
              />

              <TextField
                required
                id="mobile"
                label={t.email()}
                value={contact?.mail}
                errorText={errorMessage("email")}
                onChange={setEmail}
              />
            </div>
            <Button variant="secondary" loading={loading} onClick={handleSetStep}>
              {t.continue()}
            </Button>
          </>
        )}

        {!isActive && contact?.phoneNumber && (
          <>
            <OrdererSummary contact={contact} />
            <Button variant="secondary" onClick={setEditStep}>
              {t.change()}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const OrdererSummary = ({ contact }: { contact: Contact }) => {
  return (
    <div>
      <Paragraph>
        {contact.firstName} {contact.lastName}
      </Paragraph>
      <Paragraph>{contact.mail}</Paragraph>
      <Paragraph>{contact.phoneNumber}</Paragraph>
    </div>
  );
};
