import * as React from "react";
import { ProductCard, Heading } from "@purpurds/purpur";
import { useMessage } from "@messageformat/react";
import { ProductContext } from "../context/ProductContext";
import { HardwareProduct } from "@telia/b2b-rest-client/dist/corp-proptech-service";
import { SelectedProduct } from "../typings/products";
import styles from "./ProductListing.module.scss";
import { currentLanguage } from "@telia/b2b-i18n";

const ProductListing = () => {
  const t = useMessage("priceSummary");
  const { setSelectedProducts, selectedProducts } = React.useContext(ProductContext);
  const { hardwareBundles } = React.useContext(ProductContext);

  const handleSelectProduct = (product: HardwareProduct) => {
    const selected = { ...product, quantity: 1 };
    setSelectedProducts((prevSelectedProducts: SelectedProduct[]) => [
      ...prevSelectedProducts,
      selected,
    ]);
  };
  const productSelected = (artNumber: string) => {
    return selectedProducts.some((product) => product.artNumber === artNumber);
  };

  const productPriceText = (price: string) => {
    const amount = Number(price);
    return new Intl.NumberFormat(currentLanguage(), {
      style: "currency",
      currency: "SEK",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(amount);
  };

  const productName = (product: HardwareProduct) => {
    const { sv, en } = product.name;
    if (currentLanguage() === "sv") {
      return sv || "";
    }
    return en || sv || "";
  };

  const productImage = (product: HardwareProduct) => {
    if (product.images?.length === 1) {
      return "https://telia.se" + product.images[0].url;
    }
    const image = product.images?.find((image) => !!image.main);

    if (!image?.url) return undefined;
    return "https://telia.se" + image.url;
  };

  const parseIncludedItems = (product: HardwareProduct) => {
    return product?.includedItems?.map((el) => el.name);
  };
  return (
    <div className={styles.productListing}>
      {hardwareBundles.map((bundle, index) => {
        return (
          <div className={styles.bundle} key={index}>
            <Heading variant="title-200" tag="h2">
              {bundle.name}
            </Heading>
            <div className={styles.bundleProductListing}>
              {bundle.products?.map((product) => {
                return (
                  <ProductCard
                    key={product.artNumber}
                    title={productName(product)}
                    bodyText={product.description}
                    screenReaderText={productName(product)}
                    priceText={productPriceText(product.price)}
                    listItems={parseIncludedItems(product)}
                    variant="primary"
                    fullHeight
                    badgeVariant="special"
                    priceDescription={t.vatExcluded()}
                    badgeText={productSelected(product.artNumber) ? "Vald" : ""}
                    disabled={productSelected(product.artNumber)}
                    onClick={() => handleSelectProduct(product)}
                    {...(product.images &&
                      product.images.length && {
                        image: <img src={productImage(product)} alt={productName(product)} />,
                      })}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductListing;
