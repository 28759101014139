import { useContext, useState } from "react";
import { ProductContext } from "../context/ProductContext";
import {
  Button,
  Heading,
  IconRemove,
  Paragraph,
  IconPlus,
  IconMinus,
  TextField,
  Notification,
} from "@purpurds/purpur";
import styles from "./Summary.module.scss";
import { SelectedProduct } from "../typings/products";
import { corpProptechService } from "@telia/b2b-rest-client";
import { UserContext } from "../context/UserContext";
import { PriceSummary } from "./PriceSummary";
import { HardwareOrder } from "@telia/b2b-rest-client/dist/corp-proptech-service";
import { useMessage } from "@messageformat/react";
import { currentLanguage } from "@telia/b2b-i18n";
import useCheckout from "../hooks/useCheckout";

const QuantityCounter: React.FC<{ product: SelectedProduct }> = ({ product }) => {
  const summary = useMessage("summary");
  const { selectedProducts, setSelectedProducts, step } = useContext(ProductContext);

  const handleIncreaseQuantity = (product: SelectedProduct) => {
    const index = selectedProducts.findIndex((el) => el.artNumber === product.artNumber);
    const products = [...selectedProducts];
    products[index].quantity++;
    setSelectedProducts(products);
  };
  const handleDecreaseQuantity = (product: SelectedProduct) => {
    if (product.quantity === 1) return;
    const index = selectedProducts.findIndex((el) => el.artNumber === product.artNumber);
    const products = [...selectedProducts];
    products[index].quantity--;
    setSelectedProducts(products);
  };
  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let quantity = Number(event.target.value);
    if (isNaN(quantity)) return;
    setProductQuantity(quantity);
  };
  const parsedQuantity = product.quantity === 0 ? "" : product.quantity;
  const handleQuantityOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setProductQuantity(1);
    }
  };
  const setProductQuantity = (quantity: number) => {
    const index = selectedProducts.findIndex((el) => el.artNumber === product.artNumber);
    const products = [...selectedProducts];
    products[index].quantity = quantity;
    setSelectedProducts(products);
  };

  const productPriceText = (price: number) => {
    return new Intl.NumberFormat(currentLanguage(), {
      style: "currency",
      currency: "SEK",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(price);
  };

  return (
    <>
      <div className={styles.inline}>
        <span className={styles.quantitySelector}>
          {step === "product-listing" && (
            <Button
              variant="secondary"
              size="sm"
              iconOnly
              disabled={product.quantity === 1}
              aria-label="decrease quantity"
              onClick={() => handleDecreaseQuantity(product)}
            >
              <IconMinus />
            </Button>
          )}
          <TextField
            id="quantity"
            className={styles.quantityInput}
            value={parsedQuantity}
            readOnly={step === "checkout"}
            onChange={handleQuantityChange}
            onBlur={handleQuantityOnBlur}
          />
          {step === "product-listing" && (
            <Button
              variant="secondary"
              size="sm"
              iconOnly
              aria-label="increase quantity"
              onClick={() => handleIncreaseQuantity(product)}
            >
              <IconPlus />
            </Button>
          )}
        </span>
        <span>
          <Paragraph>{productPriceText(product.quantity * Number(product.price))}</Paragraph>
        </span>
      </div>
    </>
  );
};

const Summary = () => {
  const error = useMessage("checkoutError");
  const summary = useMessage("summary");
  const { checkout } = useCheckout();
  const { selectedProducts, setStep, step, setSelectedProducts } = useContext(ProductContext);
  const {
    checkoutStep,
    checkoutStepCopy,
    saveChangesNotification,
    setCaseId,
    setSaveChangesNotification,
  } = useContext(UserContext);

  const [orderError, setOrderError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleRemoveProduct = (artNumber: string) => {
    const index = selectedProducts.findIndex((product) => product.artNumber === artNumber);
    const products = [...selectedProducts];
    products.splice(index, 1);
    setSelectedProducts(products);
  };

  const handleSetStep = async () => {
    switch (step) {
      case "product-listing":
        if (selectedProducts.length === 0) return;
        setStep("checkout");
        return;
      case "checkout":
        try {
          setIsSubmitting(true);
          setSaveChangesNotification(false);
          setOrderError(false);
          if (checkoutStepCopy === "other-information") {
            setSaveChangesNotification(true);
          }
          if (checkoutStep !== "other-information") {
            setSaveChangesNotification(true);
            return;
          }
          const response = await checkout();
          setCaseId(response);
          setStep("order-confirmation");
        } catch (error) {
          setOrderError(true);
        } finally {
          setIsSubmitting(false);
        }
        //setStep("order-confirmation");
        return;
    }
  };
  const getButtonLabel = () => {
    switch (step) {
      case "product-listing":
        return summary.toCheckout();
      case "checkout":
        return summary.submit();
      default:
        return summary.continue();
    }
  };

  return (
    <div className={styles.summary}>
      <Heading variant="title-200" tag="h2">
        {summary.selection()}
      </Heading>
      {selectedProducts.map((product, index) => {
        return (
          <div key={index}>
            <span className={styles.productTitle}>
              <Heading variant="subsection-100" tag="h3">
                {productName(product)}
              </Heading>
              <Button variant="text" onClick={() => handleRemoveProduct(product.artNumber)}>
                <IconRemove />
                {summary.remove()}
              </Button>
            </span>
            <QuantityCounter product={product} />
          </div>
        );
      })}
      <PriceSummary />
      <Button variant="primary" loading={isSubmitting} onClick={handleSetStep}>
        {getButtonLabel()}
      </Button>
      {/* TODO */}
      {orderError && (
        <Notification heading={error.defaultHeading()} headingTag="h3" status="warning">
          {error.defaultOrderMessage()}
        </Notification>
      )}
      {saveChangesNotification && (
        <Notification heading={error.almostDoneHeading()} headingTag="h3" status="error">
          {error.almostDoneBody()}
        </Notification>
      )}
    </div>
  );
};

const productName = (product: SelectedProduct) => {
  const { sv, en } = product.name;
  if (currentLanguage() === "sv") {
    return sv || en || "";
  }
  return en || sv || "";
};

export default Summary;
