
export default {
  pageTitle: {
    main: () => "Hårdvara för fastighetsägare",
    orderConfirmation: () => "Tack för din beställning!"
  },
  summary: {
    toCheckout: () => "Gå vidare till kassan",
    submit: () => "Slutför beställning",
    remove: () => "Ta bort",
    "continue": () => "Gå vidare",
    selection: () => "Dina val",
    kr: () => "kr"
  },
  checkout: {
    delivery: () => "Leveransadress",
    companyName: () => "Företagsnamn",
    address: () => "Adress",
    receiver: () => "Mottagare",
    "continue": () => "Fortsätt",
    contact: () => "Kontakt",
    billing: () => "Fakturainformation",
    firstname: () => "Förnamn",
    lastname: () => "Efternamn",
    email: () => "Mejladress",
    mobileNumber: () => "Mobilnummer",
    reference: () => "Referens eller anteckning",
    referenceHelpText: () => "Om du till exempel har ett eget ordernummer",
    change: () => "Ändra",
    invoiceReference: () => "Fakturareferens",
    invoiceAddress: () => "Fakturaadress",
    invoiceAdditionalInfo: () => "Övrig adressinformation (valfritt)",
    additionalInfoHelperText: () => "Här kan du ange till exempel c/o eller ATT.",
    otherInformation: () => "Annan information",
    invoiceTypeText: () => "Vi skickar fakturorna med post. Vi kan tyvärr inte skicka e-faktura.",
    firstAndLastName: () => "För- och efternamn",
    orderer: () => "Beställare"
  },
  orderConfirmation: {
    products: () => "Produkter",
    orderNumber: () => "Ordernummer:",
    details: () => "Detaljer",
    amount: () => "Antal:",
    deliveryDetails: () => "Leveransuppgifter",
    billingAccount: () => "Fakturainformation",
    receiverInfo: () => "Motagarinformation",
    followOrder: () => "Följ order",
    toProductsAndServices: () => "Till produkter & tjänster",
    confirmationText: () => "Bekräftelse och sammanställning har också skickats ut till din e-postadress"
  },
  priceSummary: {
    total: () => "Totalt",
    oneTimeFee: () => "Engångskostnad",
    exclVat: () => "Alla priser visas exklusive moms.",
    kr: () => "kr",
    vatExcluded: () => "Exklusive moms"
  },
  contactError: {
    firstname: () => "Förnamn är obligatoriskt",
    lastname: () => "Efternamn är obligatoriskt",
    email: () => "Email är obligatoriskt",
    mobile: () => "Mobilnummer är obligatoriskt"
  },
  checkoutError: {
    defaultHeading: () => "Något gick fel!",
    defaultOrderMessage: () => "Tyvärr kunde vi inte hantera din order just nu, vänligen försök igen senare.",
    companyName: () => "Företagsnamn är obligatoriskt",
    address: () => "Adress är obligatoriskt",
    "default": () => "Detta fält är obligatoriskt",
    invalid: () => "Ogiltligt format",
    almostDoneHeading: () => "Nästan klar!",
    almostDoneBody: () => "Spara alla ändringar för att slutföra order."
  },
  fetchError: {
    access: () => "Vi kunde tyvärr inte läsa in dina behörigheter just nu, vänligen ladda om eller försök igen senare.",
    organisation: () => "Vi kunde tyvärr inte ladda in organisationsuppgifter, vänligen försök igen senare.",
    products: () => "Vi kunde tyvärr inte hämta produkter just nu, vänligen försök igen senare.",
    userInfo: () => "Vi kunde tyvärr inte hämta användaruppgifter, vänligen försök igen senare.",
    noPermissionHeading: () => "Ingen behörighet",
    noPermissionMessage: () => "Vald organisation saknar behörighet att beställa hårdvara för fastigheter.",
    defaultHeading: () => "Något gick fel!"
  }
}