import { Heading, TextArea } from "@purpurds/purpur";
import { useMessage } from "@messageformat/react";
import styles from "./Checkout.module.scss";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";

type Props = {
  isActive: boolean;
};

export const OtherInformation = ({ isActive }: Props) => {
  const checkout = useMessage("checkout");
  const { otherInformation, setOtherInformation } = useContext(UserContext);

  return (
    <div className={styles.cardContent}>
      <Heading tag="h2" variant="title-200">
        {checkout.otherInformation()}
      </Heading>
      {isActive && (
        <>
          <TextArea
            id="other-information"
            className={styles.otherInformationField}
            label={checkout.otherInformation()}
            value={otherInformation}
            onChange={(e) => setOtherInformation(e.target.value)}
          />
        </>
      )}
    </div>
  );
};
