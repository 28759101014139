import { ProductContext } from "../context/ProductContext";
import { Heading, Card, Button } from "@purpurds/purpur";
import styles from "./Checkout.module.scss";
import { useMessage } from "@messageformat/react";
import { Delivery } from "./Delivery";
import { Contact } from "./Contact";
import { Invoice } from "./Invoice";
import { OtherInformation } from "./OtherInformation";
import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { CheckoutStep } from "../typings/products";
import { Orderer } from "./Orderer";
interface CardProps {
  children?: React.ReactNode;
}

const Section = ({ children }: CardProps) => {
  return (
    <Card variant="primary">
      <Card.ContentContainer>
        <Card.Content>{children}</Card.Content>
      </Card.ContentContainer>
    </Card>
  );
};

const Checkout = () => {
  const {
    scopeId,
    checkoutStep,
    checkoutStepCopy,
    setCheckoutStep,
    setCheckoutStepCopy,
    setSaveChangesNotification,
  } = useContext(UserContext);

  const handleSetStep = (value: CheckoutStep) => {
    if (checkoutStepCopy) {
      setCheckoutStep(checkoutStepCopy);
      setCheckoutStepCopy(undefined);
      return;
    }
    setSaveChangesNotification(false);
    setCheckoutStep(value);
  };
  const handleSetEditStep = (value: CheckoutStep) => {
    setCheckoutStepCopy(checkoutStep);
    setCheckoutStep(value);
  };
  const isActive = (step: CheckoutStep) => {
    return step === checkoutStep;
  };
  return (
    <div className={styles.checkout}>
      <Section>
        <Delivery
          scopeId={scopeId}
          isActive={isActive("delivery")}
          setStep={() => handleSetStep("invoice")}
          setEditStep={() => handleSetEditStep("delivery")}
        />
      </Section>
      <Section>
        <Invoice
          isActive={isActive("invoice")}
          scopeId={scopeId}
          setStep={() => handleSetStep("orderer")}
          setEditStep={() => handleSetEditStep("invoice")}
        />
      </Section>
      <Section>
        <Orderer
          isActive={isActive("orderer")}
          scopeId={scopeId}
          setStep={() => handleSetStep("other-information")}
          setEditStep={() => handleSetEditStep("orderer")}
        />
      </Section>
      <Section>
        <OtherInformation isActive={isActive("other-information")} />
      </Section>
    </div>
  );
};

export default Checkout;
