import { Button, Card, Heading, Paragraph, TextField, Notification } from "@purpurds/purpur";
import { B2bAddressSearch } from "@telia/b2b-address-search/react-cjs";
import styles from "./Checkout.module.scss";
import { useMessage } from "@messageformat/react";
import { AddressInformation, B2bAddressSearchCustomEvent } from "@telia/b2b-address-search";
import { ChangeEvent, useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { Receiver } from "../typings/products";

type Props = {
  setStep: () => void;
  setEditStep: () => void;
  isActive: boolean;
  scopeId: string;
};
type ValidFields =
  | "companyName"
  | "address"
  | "receiverFirstname"
  | "receiverLastname"
  | "receiverMobile";

export const Delivery = ({ setStep, setEditStep, isActive }: Props) => {
  const checkout = useMessage("checkout");
  const error = useMessage("checkoutError");
  const [valid, setValid] = useState({
    companyName: true,
    address: true,
    firstname: true,
    lastname: true,
    mobile: true,
  });
  const {
    scopeId,
    address,
    setAddress,
    companyName,
    setCompanyName,
    receiver,
    setReceiver,
    saveChangesNotification,
  } = useContext(UserContext);

  const handleCompanyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValid({ ...valid, companyName: true });
    setCompanyName(e.target.value);
  };
  const handleFirstNameReceiverChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValid({ ...valid, firstname: true });
    setReceiver({ ...receiver, firstname: e.target.value });
  };
  const handleLastNameReceiverChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValid({ ...valid, lastname: true });
    setReceiver({ ...receiver, lastname: e.target.value });
  };
  const handleMobileReceiverChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValid({ ...valid, mobile: true });
    setReceiver({ ...receiver, mobile: e.target.value });
  };
  const handleAddressChange = (e: B2bAddressSearchCustomEvent<AddressInformation | null>) => {
    setAddress(e.detail ?? undefined);
    if (e.detail) setValid({ ...valid, address: true });
  };

  const handleSetStep = async () => {
    const isValid = await validate();
    if (!isValid) return;

    setStep();
  };
  const validate = async () => {
    let updatedValid = {
      companyName: true,
      address: true,
      firstname: true,
      lastname: true,
      mobile: true,
    };
    if (!address) {
      updatedValid.address = false;
    }
    if (companyName === "") {
      updatedValid.companyName = false;
    }
    if (receiver.firstname === "") {
      updatedValid.firstname = false;
    }
    if (receiver.lastname === "") {
      updatedValid.lastname = false;
    }
    if (receiver.mobile === "") {
      updatedValid.mobile = false;
    }
    setValid(updatedValid);
    return Object.values(updatedValid).every((value) => value === true);
  };

  const errorMessage = (field: ValidFields) => {
    if (!valid.companyName && field === "companyName") {
      return error.companyName();
    }
    if (!valid.address && field === "address") {
      return error.default();
    }
    if (!valid.firstname && field === "receiverFirstname") {
      return error.default();
    }
    if (!valid.lastname && field === "receiverLastname") {
      return error.default();
    }
    if (!valid.mobile && field === "receiverMobile") {
      return error.default();
    }

    return undefined;
  };

  return (
    <div className={styles.card}>
      <Heading variant="title-200" tag="h2">
        {checkout.delivery()}
      </Heading>

      {isActive && (
        <div className={styles.cardContent}>
          <TextField
            required
            value={companyName}
            label={checkout.companyName()}
            errorText={errorMessage("companyName")}
            onChange={handleCompanyNameChange}
            id="organisation"
          />

          <B2bAddressSearch
            label={checkout.address()}
            scopeId={scopeId}
            debounceTimeoutMs={300}
            manualAddressEnabled
            defaultAddress={JSON.stringify(address)}
            error={errorMessage("address")}
            onAddressSelected={handleAddressChange}
            onFocus={() => setValid({ ...valid, address: true })}
            required
          />

          <Paragraph>{checkout.receiver()}</Paragraph>

          <span className={styles.inline}>
            <TextField
              required
              id="receiver-firstname"
              label={checkout.firstname()}
              value={receiver.firstname}
              errorText={errorMessage("receiverFirstname")}
              onChange={handleFirstNameReceiverChange}
              onFocus={() => setValid({ ...valid, firstname: true })}
            />
            <TextField
              required
              id="receiver-lastname"
              label={checkout.lastname()}
              value={receiver.lastname}
              errorText={errorMessage("receiverLastname")}
              onChange={handleLastNameReceiverChange}
              onFocus={() => setValid({ ...valid, lastname: true })}
            />
          </span>
          <TextField
            required
            id="receiver-mobile"
            label={checkout.mobileNumber()}
            value={receiver.mobile}
            errorText={errorMessage("receiverMobile")}
            onChange={handleMobileReceiverChange}
            onFocus={() => setValid({ ...valid, mobile: true })}
          />
        </div>
      )}
      {!isActive && address && (
        <>
          <AddressSummary address={address} companyName={companyName} receiver={receiver} />
          <Button variant="secondary" onClick={setEditStep}>
            {checkout.change()}
          </Button>
        </>
      )}

      {isActive && (
        <Button variant="secondary" onClick={handleSetStep}>
          {checkout.continue()}
        </Button>
      )}
      {saveChangesNotification && isActive && (
        <Notification heading={error.almostDoneHeading()} headingTag="h3" status="error">
          {error.almostDoneBody()}
        </Notification>
      )}
    </div>
  );
};

type AddressSummaryProps = { address: AddressInformation; companyName: string; receiver: Receiver };
const AddressSummary = ({ address, companyName, receiver }: AddressSummaryProps) => {
  const checkout = useMessage("checkout");
  return (
    <div className={styles.deliverySummary}>
      <span>
        <Paragraph>{companyName}</Paragraph>
        <Paragraph>
          {address.streetName} {address.streetNumber} {address.entrance}
        </Paragraph>
        <Paragraph>
          {address.postalCode} {address.city}
        </Paragraph>
      </span>
      <span>
        <Heading tag="h3" variant="subsection-100">
          {checkout.receiver()}
        </Heading>
        <Paragraph>
          {receiver.firstname} {receiver.lastname}
        </Paragraph>
        <Paragraph>{receiver.mobile}</Paragraph>
      </span>
    </div>
  );
};
