import { useContext } from "react";
import { useMessage } from "@messageformat/react";
import ProductListing from "./ProductListing";
import Checkout from "./Checkout";
import { Grid, Notification, Paragraph, Skeleton, Stepper } from "@purpurds/purpur";
import { ProductContext } from "../context/ProductContext";
import { UserContext } from "../context/UserContext";
import Summary from "./Summary";
import { B2bLayout } from "@telia/b2b-layout/react-cjs";
import styles from "./Root.module.scss";
import { OrderConfirmation } from "./OrderConfirmation";

const Root = () => {
  const { step, fetchingProducts, setStep, selectedProducts } = useContext(ProductContext);
  const { setTscid, tscid, scopeId, hasAccess, fetchError } = useContext(UserContext);
  const pageTitle = useMessage("pageTitle");
  const error = useMessage("fetchError");
  const getPageTitle = () => {
    if (step === "order-confirmation") return pageTitle.orderConfirmation();

    return pageTitle.main();
  };

  const handleSetTscid = async (e: string) => {
    setTscid(e);
  };

  const disableCheckoutStep = () => {
    if (selectedProducts.length) return false;
    return true;
  };

  const getErrorMessage = () => {
    switch (fetchError) {
      case "access":
        return error.access();
      case "organisation":
        return error.organisation();
      case "products":
        return error.products();
      case "userInfo":
        return error.userInfo();
    }
  };

  const handleStepChange = (newStep: number) => {
    if (newStep === 0) {
      setStep("product-listing");
    }
    if (newStep === 1) {
      setStep("checkout");
    }
  };

  const getStepIndex = () => {
    if (step === "product-listing") return 0;
    return 1;
  };

  const showTabs = () => {
    if (step === "order-confirmation") return false;
    return true;
  };

  const scrollButtonLabels = {
    leftScrollButtonText: "Scroll left",
    rightScrollButtonText: "Scroll right",
  };

  return (
    <div data-testid="b2b-proptech-hardware-root" id="main" className={styles.proptechHardware}>
      <B2bLayout
        heading={getPageTitle()}
        page-title={pageTitle}
        showOrganisationSelector={true}
        hasTabs={showTabs()}
        {...{
          onOrganisationSelected: (event: CustomEvent) => {
            handleSetTscid(event.detail);
          },
        }}
      >
        {fetchError ? (
          <div className={styles.notificationWrapper}>
            <Grid>
              <Notification heading={error.defaultHeading()} headingTag="h3">
                <Paragraph>{getErrorMessage()}</Paragraph>
              </Notification>
            </Grid>
          </div>
        ) : (
          <>
            {(hasAccess === "loading" || fetchingProducts) && <SkeletonLoader />}
            {hasAccess === "denied" && <DeniedAccess />}
            {hasAccess === "access" && (
              <div className={styles.content}>
                <Grid>
                  {showTabs() && (
                    <>
                      <Stepper
                        aria-label="stepper"
                        variant="primary"
                        onStepChange={handleStepChange}
                        currentStepIndex={getStepIndex()}
                        scrollButtonLabels={scrollButtonLabels}
                        completedStepLabel="Completed"
                      >
                        <Stepper.Content
                          label="Products"
                          finished={step === "checkout"}
                          className="products"
                        >
                          <span />
                        </Stepper.Content>
                        <Stepper.Content
                          label="Checkout"
                          className="checkout"
                          disabled={disableCheckoutStep()}
                        >
                          <span />
                        </Stepper.Content>
                      </Stepper>
                    </>
                  )}
                  {step !== "order-confirmation" && (
                    <div className={styles.contentWrapper}>
                      <Grid>
                        <Grid.Item colSpanSm={4} colSpanMd={8} colSpanLg={8}>
                          {step === "product-listing" && <ProductListing />}
                          {step === "checkout" && <Checkout />}
                        </Grid.Item>
                        <Grid.Item
                          className={styles.summaryCol}
                          colSpanSm={4}
                          colSpanMd={8}
                          colSpanLg={4}
                        >
                          <Summary />
                        </Grid.Item>
                      </Grid>
                    </div>
                  )}
                  {step === "order-confirmation" && (
                    <>
                      <OrderConfirmation />
                    </>
                  )}
                </Grid>
              </div>
            )}
          </>
        )}
      </B2bLayout>
    </div>
  );
};

const SkeletonLoader = () => {
  return (
    <div className={styles.skeletonWrapper}>
      <Grid>
        <Grid.Item colSpanSm={4} colSpanMd={8} colSpanLg={8}>
          <div className={styles.productSkeletonWrapper}>
            <span className={styles.inlineSkeleton}>
              <Skeleton radius="md" className={styles.skeletonCard} />
              <Skeleton radius="md" className={styles.skeletonCard} />
            </span>
            <span className={styles.inlineSkeleton}>
              <Skeleton radius="md" className={styles.skeletonCard} />
              <Skeleton radius="md" className={styles.skeletonCard} />
            </span>
          </div>
        </Grid.Item>
        <Grid.Item className={styles.summaryCol} colSpanSm={4} colSpanMd={8} colSpanLg={4}>
          <Skeleton radius="md" className={styles.skeletonSummary} />
        </Grid.Item>
      </Grid>
    </div>
  );
};

const DeniedAccess = () => {
  const error = useMessage("fetchError");
  return (
    <div className={styles.notificationWrapper}>
      <Grid>
        <Notification heading={error.noPermissionHeading()} headingTag="h3">
          <Paragraph>{error.noPermissionMessage()}</Paragraph>
        </Notification>
      </Grid>
    </div>
  );
};

export default Root;
