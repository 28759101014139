import { HardwareOrder } from "@telia/b2b-rest-client/dist/corp-proptech-service";
import { corpProptechService } from "@telia/b2b-rest-client";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { ProductContext } from "../context/ProductContext";

const useCheckout = () => {
  const {
    scopeId,
    tscid,
    companyName,
    address,
    invoiceAddress,
    invoiceReference,
    contact,
    receiver,
    otherInformation,
  } = useContext(UserContext);
  const { selectedProducts } = useContext(ProductContext);

  const orderData = (): HardwareOrder => {
    const formattedProducts = selectedProducts.map((product) => {
      return {
        name: product.name.sv ?? "",
        quantity: product.quantity ?? 0,
        artNumber: product.artNumber ?? "",
        price: product.price ?? "",
      };
    });

    return {
      account: {
        organisationName: companyName,
      },
      orderer: {
        firstName: contact.firstName,
        lastName: contact.lastName,
        mobile: contact.phoneNumber,
        email: contact.mail,
      },
      recipient: {
        firstName: receiver.firstname,
        lastName: receiver.lastname,
        mobile: receiver.mobile,
      },
      deliveryAddress: {
        street: address?.streetName ?? "",
        streetNumber: address?.streetNumber ?? "",
        postalCode: address?.postalCode ?? "",
        entrance: address?.entrance ?? "",
        city: address?.city ?? "",
      },
      billingAddress: {
        reference: invoiceReference,
        street: invoiceAddress?.streetName ?? "",
        streetNumber: invoiceAddress?.streetNumber ?? "",
        postalCode: invoiceAddress?.postalCode ?? "",
        entrance: invoiceAddress?.entrance,
        city: invoiceAddress?.city ?? "",
      },
      extraInformation: otherInformation,
      products: formattedProducts,
    };
  };

  const checkout = async () => {
    try {
      const data = orderData();
      const response = await corpProptechService.HardwareControllerService.checkout(
        scopeId as string,
        tscid,
        data
      );
      return response.caseId;
    } catch (e) {
      throw new Error("failed to checkout");
    }
  };

  return {
    checkout,
  };
};

export default useCheckout;
