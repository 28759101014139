// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.esnQF4dUItlCAA9HxKj3{position:sticky;top:var(--purpur-spacing-400);display:flex;flex-direction:column;gap:var(--purpur-spacing-400);background-color:var(--purpur-color-brand-white);padding:var(--purpur-spacing-400);border-radius:10px}.Dr3FRD74D0n8Brxxl9Ct{display:flex;justify-content:space-between;align-items:center}.Nf43JSQ9MOvCBoNuI2r_{display:flex;gap:var(--purpur-spacing-100)}.Nf43JSQ9MOvCBoNuI2r_:hover{cursor:pointer;color:var(--purpur-color-red-500)}.EQNW7xRL_jVJy9qefWcD{display:flex;gap:var(--purpur-spacing-150);align-items:center}.lEzJsubvYN9P11f3tE7H{width:6.5rem}.lEzJsubvYN9P11f3tE7H input{text-align:center}.Fane7RkgRT09O9qnlnqI{display:flex;justify-content:space-between;align-items:end}`, "",{"version":3,"sources":["webpack://./src/components/Summary.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,6BAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,gDAAA,CACA,iCAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CAEF,4BACE,cAAA,CACA,iCAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CAEA,4BACE,iBAAA,CAGJ,sBACE,YAAA,CACA,6BAAA,CACA,eAAA","sourcesContent":[".summary {\n  position: sticky;\n  top: var(--purpur-spacing-400);\n  display: flex;\n  flex-direction: column;\n  gap: var(--purpur-spacing-400);\n  background-color: var(--purpur-color-brand-white);\n  padding: var(--purpur-spacing-400);\n  border-radius: 10px;\n}\n.productTitle {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.removeProduct {\n  display: flex;\n  gap: var(--purpur-spacing-100);\n}\n.removeProduct:hover {\n  cursor: pointer;\n  color: var(--purpur-color-red-500);\n}\n.quantitySelector {\n  display: flex;\n  gap: var(--purpur-spacing-150);\n  align-items: center;\n}\n.quantityInput {\n  width: 6.5rem;\n\n  input {\n    text-align: center;\n  }\n}\n.inline {\n  display: flex;\n  justify-content: space-between;\n  align-items: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary": `esnQF4dUItlCAA9HxKj3`,
	"productTitle": `Dr3FRD74D0n8Brxxl9Ct`,
	"removeProduct": `Nf43JSQ9MOvCBoNuI2r_`,
	"quantitySelector": `EQNW7xRL_jVJy9qefWcD`,
	"quantityInput": `lEzJsubvYN9P11f3tE7H`,
	"inline": `Fane7RkgRT09O9qnlnqI`
};
export default ___CSS_LOADER_EXPORT___;
