// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zlkx3s9BE0D61AOD1RTK{display:flex;flex-direction:column;gap:var(--purpur-spacing-600)}.vYIunyrWF77Evre0iIx9{display:flex;flex-direction:column;gap:var(--purpur-spacing-200)}.hkUBcBaDNGzU2KhJSQWc{display:grid;grid-template-columns:1fr 1fr;gap:var(--purpur-spacing-300)}@media(max-width: 600px){.hkUBcBaDNGzU2KhJSQWc{grid-template-columns:1fr}}`, "",{"version":3,"sources":["webpack://./src/components/ProductListing.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,6BAAA,CAEA,yBALF,sBAMI,yBAAA,CAAA","sourcesContent":["@use \"~@purpurds/tokens/breakpoint/variables\";\n\n.productListing {\n  display: flex;\n  flex-direction: column;\n  gap: var(--purpur-spacing-600);\n}\n.bundle {\n  display: flex;\n  flex-direction: column;\n  gap: var(--purpur-spacing-200);\n}\n\n.bundleProductListing {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: var(--purpur-spacing-300);\n\n  @media (max-width: variables.$purpur-breakpoint-md) {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productListing": `zlkx3s9BE0D61AOD1RTK`,
	"bundle": `vYIunyrWF77Evre0iIx9`,
	"bundleProductListing": `hkUBcBaDNGzU2KhJSQWc`
};
export default ___CSS_LOADER_EXPORT___;
