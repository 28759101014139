// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P16hRXhocJb0LpaElNK0{display:flex;flex-direction:column;gap:var(--purpur-spacing-250)}.R5gjCwUEh9ymUpzm_BwX{display:grid;grid-template-columns:1fr 1fr;gap:var(--purpur-spacing-250)}._FegZ2_7g1nZkvPZBpjJ{display:flex;gap:var(--purpur-spacing-200)}`, "",{"version":3,"sources":["webpack://./src/components/OrderConfirmation.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,6BAAA,CAEF,sBACE,YAAA,CACA,6BAAA","sourcesContent":[".leftColumn {\n  display: flex;\n  flex-direction: column;\n  gap: var(--purpur-spacing-250);\n}\n\n.detailInformation {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: var(--purpur-spacing-250);\n}\n.buttonWrapper {\n  display: flex;\n  gap: var(--purpur-spacing-200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftColumn": `P16hRXhocJb0LpaElNK0`,
	"detailInformation": `R5gjCwUEh9ymUpzm_BwX`,
	"buttonWrapper": `_FegZ2_7g1nZkvPZBpjJ`
};
export default ___CSS_LOADER_EXPORT___;
