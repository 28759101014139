import { Grid, Heading, Card, Paragraph, Button, IconArrowRight, CtaLink } from "@purpurds/purpur";
import { ProductContext } from "../context/ProductContext";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";
import styles from "./OrderConfirmation.module.scss";
import { PriceSummary } from "./PriceSummary";
import { useMessage } from "@messageformat/react";
import { SelectedProduct } from "../typings/products";
import { currentLanguage } from "@telia/b2b-i18n";

interface CardProps {
  children?: React.ReactNode;
}

const Section = ({ children }: CardProps) => {
  return (
    <Card variant="primary">
      <Card.ContentContainer>
        <Card.Content>{children}</Card.Content>
      </Card.ContentContainer>
    </Card>
  );
};

export const OrderConfirmation = () => {
  const t = useMessage("orderConfirmation");
  const { resetToDefault } = useContext(ProductContext);
  const { caseId, scopeId } = useContext(UserContext);
  return (
    <div>
      <Grid>
        <Grid.Item className={styles.leftColumn} colSpanSm={4} colSpanMd={8} colSpanLg={8}>
          <Section>
            <Heading tag="h2" variant="title-200">
              {t.orderNumber()} {caseId}
            </Heading>
            <Paragraph>{t.confirmationText()}</Paragraph>
            <span className={styles.buttonWrapper}>
              <CtaLink
                variant="primary"
                href={`/foretag/mybusiness/${scopeId}/support/arenden/detaljer/${caseId}`}
              >
                {t.followOrder()}
              </CtaLink>
              <CtaLink
                variant="primary"
                href={`/foretag/mybusiness/${scopeId}/bestall/produkter-tjanster`}
              >
                {t.toProductsAndServices()}
              </CtaLink>
            </span>
          </Section>
          <Section>
            <Heading tag="h2" variant="title-200">
              {t.details()}
            </Heading>
            <div className={styles.detailInformation}>
              <DeliveryDetails />
              <InvoiceDetails />
              <ReceiverDetails />
            </div>
          </Section>
        </Grid.Item>
        <Grid.Item colSpanSm={4} colSpanMd={4} colSpanLg={4}>
          <Section>
            <ProductSummary />
          </Section>
        </Grid.Item>
      </Grid>
    </div>
  );
};

const ProductSummary = () => {
  const t = useMessage("orderConfirmation");
  const { selectedProducts } = useContext(ProductContext);
  return (
    <>
      <Heading tag="h2" variant="title-200">
        {t.products()}
      </Heading>
      {selectedProducts.map((product) => {
        return (
          <>
            <span>
              <Paragraph variant="paragraph-100-bold">{productName(product)}</Paragraph>
              <Paragraph>
                {t.amount()} {product.quantity}
              </Paragraph>
            </span>
          </>
        );
      })}
      <PriceSummary />
    </>
  );
};

const DeliveryDetails = () => {
  const t = useMessage("orderConfirmation");
  const { address, companyName } = useContext(UserContext);
  return (
    <div className={styles.deliveryDetails}>
      <Paragraph variant="paragraph-100-bold">{t.deliveryDetails()}</Paragraph>
      <Paragraph>{companyName}</Paragraph>
      <Paragraph>
        {address?.streetName} {address?.streetNumber}
      </Paragraph>
      <Paragraph>
        {address?.postalCode} {address?.city}
      </Paragraph>
    </div>
  );
};

const InvoiceDetails = () => {
  const t = useMessage("orderConfirmation");
  const { invoiceReference, invoiceAddress } = useContext(UserContext);
  return (
    <div className={styles.deliveryDetails}>
      <Paragraph variant="paragraph-100-bold">{t.billingAccount()}</Paragraph>
      <Paragraph>{invoiceReference}</Paragraph>
      <Paragraph>
        {invoiceAddress?.streetName} {invoiceAddress?.streetNumber}
      </Paragraph>
      <Paragraph>
        {invoiceAddress?.postalCode} {invoiceAddress?.city}
      </Paragraph>
    </div>
  );
};

const ReceiverDetails = () => {
  const t = useMessage("orderConfirmation");
  const { contact } = useContext(UserContext);
  return (
    <div className={styles.deliveryDetails}>
      <Paragraph variant="paragraph-100-bold">{t.receiverInfo()}</Paragraph>
      <Paragraph>
        {contact?.firstName} {contact?.lastName}
      </Paragraph>
      <Paragraph>{contact?.phoneNumber}</Paragraph>
      <Paragraph>{contact?.mail}</Paragraph>
    </div>
  );
};

const productName = (product: SelectedProduct) => {
  const { sv, en } = product.name;
  if (currentLanguage() === "sv") {
    return sv || "";
  }
  return en || sv || "";
};
